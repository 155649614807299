import { Link } from 'gatsby';
import { useMemo } from 'react';
import { Col, Image, Row } from 'react-bootstrap';
import Layout from '../../components/Layout';
import SEO from '../../components/SEO';
import CoverAura from '../../images/products/aura-playing-cards/cover.jpg';
import CoverWaltty from '../../images/products/the-waltty-deck/cover.jpg';

function Products() {
  const products = useMemo(
    () => [
      {
        id: 1,
        title: 'The Waltty Deck',
        nums: 0,
        image: CoverWaltty,
        alt: 'The Waltty Deck',
        url: '/products/the-waltty-deck/',
      },
      {
        id: 2,
        title: 'Aura Playing Cards',
        nums: 0,
        image: CoverAura,
        alt: 'Aura Playing Cards',
        url: '/products/aura-playing-cards/',
      },
    ],
    [],
  );

  return (
    <Layout>
      <SEO title="Products" />
      <Row className="text-center container mx-auto py-5">
        <Col>
          <Row>
            <Col>
              <h1>Products</h1>
            </Col>
          </Row>
          <Row className="mb-5">
            <Col>
              <h5>
                Our goal is simple: to create something touching in playing
                cards.
              </h5>
            </Col>
          </Row>
          <Row>
            {products.map((product) => (
              <Col key={product.id} xs={12} sm={4}>
                <Link to={product.url} className="text-decoration-none">
                  <Image
                    src={product.image}
                    alt={product.alt}
                    className="w-100"
                  />
                  <div className="bg-white py-2 mb-4">
                    <h4>{product.title}</h4>
                    {product.nums === 0 && <div className="text-secondary">SOLD OUT</div>}
                  </div>
                </Link>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    </Layout>
  );
}

export default Products;
